import { formatICBName } from './ods'

export const getBusinessProcessesList = surveyQuestions => {
  return surveyQuestions.businessProcessSurvey.processes
    .filter(process => !process.deprecated)
    .sort((a, b) => a.name.localeCompare(b.name))
}

export const formatSurveyType = type => {
  if (type === 'pre-implementation') return 'Pre-implementation'
  if (type === 'post-implementation') return 'Pre-implementation'
}
