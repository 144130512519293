import React from 'react'

const CRMP = ({ product, survey }) => {
  const programme = `implementation of ${product.name}`

  return (
    <>
      <h3>Clinical Risk Management Plan</h3>

      <h4>Introduction</h4>
      <h5>Purpose of Document</h5>
      <p>
        The purpose of the Clinical Risk Management Plan (CRMP) is to define the implementation of,
        and any variation to, the {survey.organisationName} Clinical Safety Management System. It
        describes how the {programme} will conduct clinical risk management to ensure patient safety
        with respect to services provided and the interrelated and interactive activities that will
        occur to ensure that the {programme} meets the requirements of DCB0129 and DCB0160.
      </p>
      <p>
        In fulfilling this purpose, any variation to the standard practices and procedures to be
        followed, as defined by the Clinical Risk Management System (CRMS), when performing the
        activities of the programme are documented here in this document.
      </p>
      <p>
        This CRMP identifies the means by which the {programme} shall be controlled to ensure that
        the safety work is of high quality, conforms to the requirements of the CSMS and any
        specific programme requirements.
      </p>
      <p>
        This document will be updated when the plan changes in any way as to deviate from what has
        been committed to deliver. This will be decided by the {programme} and Clinical Safety
        Officer and/or Team.
      </p>
      <h5>Background to clinical safety standards and requirements</h5>
      <p>
        Information standards provide the mechanism for introducing requirements to the NHS, those
        with whom it commissions services and its IT system suppliers. There are two Information
        Standards related to patient safety described below.
      </p>
      <h5>
        DCB0129: Clinical Risk Management: its Application in the Manufacture of Health IT Systems
      </h5>
      <p>
        This standard sets clinical risk management standards for manufacturers of Health IT
        systems. It requires the manufacturer to establish a structure within which clinical risks
        associated with the design and development of a new Health IT system or the modification of
        an existing system are properly managed. It also ensures that outputs are clearly documented
        to provide evidence of compliance. Compliance with the standard ensures that the
        manufacturer has instigated a best practice clinical safety programme during the manufacture
        of the health IT system.
      </p>
      <h5>
        DCB0160: Clinical Risk Management: its Application in the Deployment and Use of Health IT
        Systems
      </h5>
      <p>
        This standard requires health organisations deploying and using new or modified health IT
        systems to have a structure to manage clinical risks associated with that deployment. Many
        of the requirements in DCB0129 are repeated in DCB0160 for the health organisations.
      </p>
      <br />

      <h4>Overview & Clinical Safety - {programme}</h4>
      <p>
        Provide an overview of the programme or project in which this document relates to. Include a
        high-level summary of the aims and scope only. Any detailed information can be referenced
        out to existing documentation held in the programme document files. Part of the summary
        should highlight the timeline for delivery of any part of the programme and also the scope
        in relation to clinical safety with any reasoning behind decisions being made.
      </p>
      <p>
        <strong>Note:</strong> any change in phase scope, content and future phase development will
        be addressed in this document by a revised CRMP document. This revised documentation will be
        agreed with the {programme} and clinical safety officer to ensure the correct governance and
        control is in place.
      </p>

      <h4>Impact of DCB0129 and DCB0160 on {programme}</h4>
      <p>
        Whilst the {programme} may not physically be manufacturing the service itself, it may
        classify as a 'Manufacturer' as defined in DCB0129:
      </p>
      <p>
        'Person or organisation with responsibility for the design, manufacture, packaging or
        labelling of a Health IT System, assembling a system, or adapting a Health IT System before
        it is placed on the market and/or put into service, regardless of whether these operations
        are carried out by that person or on that person's behalf by a third party.'
      </p>
      <p>
        Within the safety standard DCB0160 we can also assume partial responsibility in the role of
        a Health Organisation. This standard is addressed to those persons in Health Organisations
        who are responsible for ensuring clinical safety in the deployment of Health IT Systems
        through the application of clinical risk management.
      </p>
      <p>
        The {programme} will therefore adhere to all applicable requirements of DCB0129 and DCB0160
        in this regard.
      </p>
      <p>
        Note: this section provides guidance for those organisations who may fall within the scope
        of both safety standards. If this does not apply then please delete this section.
      </p>

      <h4>Clinical Risk Management File</h4>
      <p>
        The {programme} SharePoint/Intranet/file location site contains all relevant clinical safety
        documentation and will perform the function of the Clinical Risk Management File. This
        folder will be managed by the Clinical Safety Officer and Clinical Safety Engineer.
      </p>

      <h4>Individual project areas and associated assurance approaches</h4>
      <p>
        Highlight any individual areas where assurance processes differ from the normal within the
        organisation. Document any assumptions or constraints that may provide detail to the CRMP
        and overall delivery and compliance to the safety standards.
      </p>

      <h4>Resources / Personnel</h4>
      <p>
        The Clinical Safety Officer is responsible for ensuring the clinical safety of the programme
        through the application of clinical risk management. The Clinical Safety Officer is a
        suitably qualified and experienced clinician who holds current registration with their
        relevant professional body and has had appropriate training for this role.
      </p>
      <p>Key responsibilities include:</p>
      <ul>
        <li>
          approval of the Clinical Risk Management Plan to confirm that the plan is appropriate and
          achievable in the context of the Health IT System development and modification;
        </li>
        <li>
          ensuring that clinical risk management activities are completed in accordance with the
          Clinical Risk Management Plan (this document);
        </li>
        <li>
          reviewing and approving of all safety documentation including Clinical Safety Case Reports
          and Hazard Logs;
        </li>
        <li>
          reviewing evidence in the Clinical Risk Management File to ensure it is complete and
          supports the Clinical Safety Case Report;
        </li>
        <li>
          providing recommendation to GP Connect Programme whether the Service is safe to release;
          and
        </li>
        <li>escalating any unacceptable safety risks.</li>
      </ul>

      <h4>Clinical Risk Evaluation and Management</h4>
      <p>
        The clinical risk matrix, evaluation and management process used is defined below and can
        also be found in more detail within the appendix. The hazard assessment process will follow
        the standard Clinical Risk Management System approach
      </p>
      <p>
        Hazards may be identified in other ways during the development and use of the {programme}{' '}
        such as:
      </p>
      <ul>
        <li>Discovery during design of a solution by supplier or NHS Organisation;</li>
        <li>Testing of amended functionality;</li>
        <li>Ad hoc testing of live service functionality;</li>
        <li>Reporting of an incident or problem within the live service; and</li>
        <li>Identification by a member of staff within the supplier or NHS Organisation</li>
      </ul>
      <p>
        For each identified hazard, the following information will be defined and recorded on the
        Hazard Sheet and summarised on the Hazard Log:
      </p>
      <ul>
        <li>Hazard number;</li>
        <li>Hazard name;</li>
        <li>Hazard description;</li>
        <li>
          Potential clinical impact – this will describe the effect of the hazard in the care
          setting and potential impact on the patient;
        </li>
        <li>
          Possible causes – these may be technical, human, error etc. A hazard may have a number of
          causes; and
        </li>
        <li>
          Existing controls – these are identified existing controls or measures that are currently
          in place and will remain in place post implementation that provide mitigation again the
          hazard, i.e. will be used as part of the initial Hazard Risk Assessment.
        </li>
      </ul>
      <p>
        Each Hazard will be discussed by the {programme} Clinical Safety team and any other
        appropriate people. They will perform the following tasks and record the outcome in the
        Hazard Sheet and a summary in the Hazard Log:
      </p>
      <ul>
        <li>Estimation of clinical risks;</li>
        <li>Clinical risk evaluation; and</li>
        <li>Clinical risk control option management.</li>
      </ul>
      <p>
        For each identified hazard estimation will be made of the clinical risk. This will include
        the severity of the hazard, the likelihood of the hazard and the resulting clinical risk.
        The estimation process will follow that established by the safety processes defined in
        DCB0129. A copy of the risk assessment matrix is provided in the appendix.
      </p>
      <p>
        <strong>Note:</strong> Any valid approach to hazard assessment and associated risk
        assessment matrices can be used. The sample provided in this document is to highlight
        documentation requirements only and does not provide a recommendation on any specific
        methodology to be used. This decision must be made by the organisation and its clinical
        safety team.
      </p>
    </>
  )
}

export default CRMP
