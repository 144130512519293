import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ArrowLeftIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline'
import CountUp from 'react-countup'

import SurveyStatusDark from '../../StackStatusDark'
import MenuOptions from './MenuOptions'
import { classNames } from '../../../utilities/general'

const HeaderSection = ({ product, surveyStacks, survey, setDeleteSurveyDialogOpen }) => {
  const { surveyUniqueCode } = useParams()

  let answersCount = 0
  let commentsCount = 0
  survey?.completions?.forEach(completion => {
    completion.answers.forEach(answer => {
      if (answer.comment || answer.rating) answersCount++
      if (answer.comment) commentsCount++
    })
  })

  return (
    <div className="divide-y divide-gray-200 bg-indigo-1000 shadow">
      <div className="px-4 py-6 sm:py-8 sm:px-12">
        <div className="flex items-center gap-4 justify-between">
          <Link
            to={`/employee-insights/product-survey/list/${surveyStacks[0].id}/${product.id}`}
            className="inline-flex items-center rounded bg-indigo-1000 px-3.5 py-2.5 text-sm text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-blue-950"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
          </Link>

          <MenuOptions setDeleteSurveyDialogOpen={setDeleteSurveyDialogOpen} />
        </div>

        <div className="my-8 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
            <span
              className={classNames(
                survey.subType === 'pre-implementation'
                  ? 'bg-cyan-100 text-cyan-700'
                  : 'bg-indigo-100 text-indigo-700',
                'inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium'
              )}
            >
              {survey.subType === 'pre-implementation'
                ? 'Pre-implementation'
                : 'Post-implementation'}
            </span>
            <h2 className="text-3xl mt-2 font-semibold tracking-tight text-indigo-300 sm:text-6xl">
              {product.name}
            </h2>
            {survey?.organisationName && (
              <div className="flex gap-2 items-center mt-2">
                <BuildingOfficeIcon className="h-8 w-8 text-white stroke-1" />
                <p className="text-2xl text-white">{survey.organisationName}</p>
              </div>
            )}

            <dl className="flex flex-row gap-4 justify-between mt-14">
              <div className="flex flex-col-reverse">
                <dt className="text-base text-white">Survey responses</dt>
                <dd className="text-5xl font-semibold tracking-tight text-indigo-300">
                  <CountUp end={survey?.completions?.length} duration={3} />
                </dd>
              </div>

              <div className="flex flex-col-reverse">
                <dt className="text-base text-white">Questions answered</dt>
                <dd className="text-5xl font-semibold tracking-tight text-green-400">
                  <CountUp end={answersCount} duration={3} />
                </dd>
              </div>

              <div className="flex flex-col-reverse">
                <dt className="text-base text-white">Comments received</dt>
                <dd className="text-5xl font-semibold tracking-tight text-yellow-300">
                  <CountUp end={commentsCount} duration={3} />
                </dd>
              </div>
            </dl>
          </div>
          <div className="lg:flex lg:flex-auto lg:justify-center">
            <div className="mt-6 mb-4 text-white">
              <SurveyStatusDark
                dark={true}
                surveyPath={`/ext/employee-insights/product-survey/${surveyUniqueCode}`}
                closedDate={survey?.closedDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection
