import React from 'react'
import { conditionalTableRowFormatting } from '../../../../utilities/general'

const ExecutiveSummaryTransferredRisks = ({ summary, survey, product }) => {
  const transferredRisksOutput = summary?.transferredRisksExecutiveSummaryOutput
  const tableCellStyles = {
    border: '1px solid #cbd5e1',
    padding: '5px',
    fontSize: '12pt',
  }

  return (
    <>
      <p>
        This Clinical Safety Case Report outlines the scope, functionality, and clinical risk
        management activities associated with the implementation of <strong>{product.name}</strong>{' '}
        in <strong>{survey.organisationName}</strong>. The clinical risk management activities for
        the implementation of {product.name} have been conducted in accordance with established
        clinical safety guidance. Hazards associated with the pilot have been identified, evaluated,
        and recorded in the {product.name} Hazard Log.
      </p>

      <h3>Summary of Findings</h3>
      <ul>
        <li>
          A total of {summary?.hazardLogOutput?.hazards.length} hazards were identified during the
          assessment
        </li>
        {/* <li>@TODO</li> */}
        {/* 7 of the 10 hazards related to hazards identified in the supplier DCB0129 hazard log 
3 additional hazards were identified from the internal workshops detailed risks relevant to staff at St Andrew’s Health Centre  */}
        <li>
          Each hazard was thoroughly evaluated to ensure that its potential effects, causes, and
          risks were clearly understood and documented
        </li>
        <li>The product's DCB0129 was analysed, with risks transferred to end users highlighted</li>
        <li>
          The organisation lead reviewed the documentation and made necessary changes with
          organisational changes
        </li>
      </ul>

      <h3>DCB0129 Hazard Log Summary</h3>
      <p>
        The DCB0129 hazard log highlights risks that deploying organisations, including{' '}
        {survey.organisationName}, should be aware of. The table below summarises hazards identified
        in the DCB0129 for {product.name}.
      </p>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableCellStyles}>Hazard Description</th>
            <th style={tableCellStyles}>Potential Impact</th>
            <th style={tableCellStyles}>Controls</th>
          </tr>
        </thead>
        <tbody>
          {transferredRisksOutput?.map((transferredRisk, index) => {
            return (
              <tr key={index} style={{ background: conditionalTableRowFormatting(index) }}>
                <td style={tableCellStyles}>{transferredRisk?.hazardDescription}</td>
                <td style={tableCellStyles}>{transferredRisk?.hazardPotentialImpact}</td>
                <td style={tableCellStyles}>{transferredRisk?.hazardControls}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default ExecutiveSummaryTransferredRisks
