import React from 'react'

const SummarySafetyStatement = ({ product, survey }) => {
  return (
    <>
      <p>
        The clinical safety statement for {survey.organisationName} is based on the following safety
        argument, assessed using two key criteria:
      </p>

      <ol>
        <li>
          <strong>Adherence to a Fit-for-Purpose Clinical Safety Process</strong>: The clinical
          safety process has been conducted in accordance with the Clinical Safety Management System
          (CSMS) and the DCB 0160 standard. This ensures that the implementation of{' '}
          <strong>{product.name}</strong> at <strong>{survey.organisationName}</strong> complies
          with mandated safety processes for health IT systems.
        </li>
        <li>
          <strong>Appropriate and Proportional Risk Mitigation</strong>: All risks identified in the
          hazard log for <strong>{survey.organisationName}</strong> have been managed using their
          associated controls to either an <strong>'Acceptable'</strong> or{' '}
          <strong>'Accepted'</strong> level, as defined by the agreed risk tolerance thresholds.
          Mitigation measures have been deemed appropriate and commensurate with the scale of risk.{' '}
        </li>
      </ol>

      <p>
        The acceptability of residual risks is contingent upon the deployment of the recommended
        controls detailed in the hazard log. Failure to implement these controls may lead to an
        increased level of risk at {survey.organisationName}.
      </p>

      <h3>Conclusion</h3>
      <p>
        Based on the mitigation evidence the programme manager for {survey.organisationName}{' '}
        confirms that this release of {product.name} is safe to proceed with deployment. Further
        assurance is dependent on the continued adherence to the recommended controls and the
        monitoring of risks throughout the product lifecycle.
      </p>
      <br />
    </>
  )
}

export default SummarySafetyStatement
