import React, { useState, useEffect } from 'react'
import { get, post } from 'aws-amplify/api'
import { useParams } from 'react-router-dom'
import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import Lottie from 'react-lottie-player'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import surveyQuestions from '../../data/surveyQuestions'
import LoadBox from '../../LoadBox'
import NextButton from './NextButton'
import ProductQuestionStep from './ProductQuestionStep'
import successLottieJson from '../../../lottieFiles/success.json'
import SimpleSelect from '../../SimpleSelect'

const ProductSurveyPage = () => {
  const [selectedRole, setSelectedRole] = useState()
  const [selectedStack, setSelectedStack] = useState()
  const [loadingSurvey, setLoadingSurveys] = useState()
  const [survey, setSurvey] = useState()
  const [surveyClosed, setSurveyClosed] = useState(false)
  const [productAnswers, setProductAnswers] = useState()
  const { surveyUniqueCode } = useParams()
  const [step, setStep] = useState(1)
  const [loadingSubmission, setSubmissionLoading] = useState(false)

  useEffect(() => {
    const getSurveys = async () => {
      setLoadingSurveys(true)

      try {
        const restOperation = get({
          apiName: 'bordercrossRest',
          path: `/public/surveys/${surveyUniqueCode}`,
        })
        const response = await restOperation.response
        const survey = await response.body.json()
        setSurvey(survey)
      } catch (err) {
        setSurveyClosed(true)
      }

      setLoadingSurveys(false)
    }

    getSurveys()
  }, [])

  const submit = async productAnswers => {
    const formattedProductAnswers = Object.entries(productAnswers).map(([productId, answers]) => {
      const formattedAnswers = surveyQuestions?.productSurvey?.[
        survey.subType
      ]?.productQuestions?.map(productQuestion => {
        return {
          id: productQuestion.id,
          rating: answers?.[`rate-${productQuestion.id}`],
          comment: answers?.[`comment-${productQuestion.id}`],
        }
      })

      return { productId, answers: formattedAnswers }
    })

    const body = {
      productAnswers: formattedProductAnswers,
      role: selectedRole,
      userOriginStack: selectedStack?.id,
    }
    await post({
      apiName: 'bordercrossRest',
      path: `/public/surveys/productSurvey/complete/${surveyUniqueCode}`,
      options: {
        body,
      },
    })
  }

  if (loadingSurvey) return <LoadBox />

  const currentProduct = survey?.products?.[step - 2]

  const updateAnswers = async data => {
    const newProductAnswers = {
      ...productAnswers,
      [currentProduct.id]: data,
    }
    setProductAnswers(newProductAnswers)

    if (step === productQuestionStepEnd) {
      setSubmissionLoading(true)
      await submit(newProductAnswers)
      setSubmissionLoading(false)
    }
  }

  const productQuestionStepStart = 2
  const productQuestionStepEnd = survey?.products?.length - 1 + productQuestionStepStart

  if (surveyClosed) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
        <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:py-12 sm:px-12">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              That link has expired...
            </h1>
            <p className="text-lg mt-4">It's possible responses for this survey have now closed.</p>
            <p className="text-lg mt-4">
              Otherwise, please try to reload the page, if the issue continues please let us know at{' '}
              <a className="hover:text-gray-600 underline" href="mailto:contact@bordercross.health">
                contact@bordercross.health
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  }

  const surveyOrganisationOptions = [
    { id: '', name: 'Other' },
    ...(survey?.stacks.sort((stackA, stackB) => {
      const stackAName = stackA.name
      const stackBName = stackB.name

      if (stackAName > stackBName) return 1
      if (stackBName > stackAName) return -1
      return 0
    }) || []),
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:py-12 sm:px-12">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Product Survey
          </h1>

          {survey?.organisationName && (
            <div className="flex gap-2 items-center mt-2">
              <BuildingOfficeIcon className="h-8 w-8 text-gray-600 stroke-1" />
              <p className="text-xl text-gray-600">{survey.organisationName}</p>
            </div>
          )}

          {step === 1 && (
            <div className="mt-6 max-w-3xl">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Introduction
              </h2>
              <p className="mt-2 text-base">
                This survey contains questions about {survey?.products?.length} product(s) your
                organisation is using or planning to use. For each product we'll ask you{' '}
                {surveyQuestions?.productSurvey?.[survey?.subType]?.productQuestions?.length} quick
                questions.
              </p>
              <p className="mt-2 text-base">
                Findings from the survey will help your organisation make better decisions around
                it's technology, so thanks for taking part.
              </p>

              <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      Don't share any sensitive or urgent information in this survey.
                    </p>
                  </div>
                </div>
              </div>

              {survey?.stacks?.length > 1 && (
                <div className="mt-8">
                  <SimpleSelect
                    labelClasses="!text-base font-semibold"
                    optionClasses="!text-base py-3 pl-5"
                    inputClasses="!text-base py-3 pl-5"
                    options={surveyOrganisationOptions}
                    onChange={stackId => {
                      setSelectedStack(stackId)
                    }}
                    selected={selectedStack}
                    label={'Select your organisation'}
                  />
                </div>
              )}

              <div className="mt-8">
                <SimpleSelect
                  labelClasses="!text-base font-semibold"
                  optionClasses="!text-base py-3 pl-5"
                  inputClasses="!text-base py-3 pl-5"
                  options={surveyQuestions.productSurvey.introductionQuestions[0].options}
                  onChange={setSelectedRole}
                  selected={selectedRole}
                  label={surveyQuestions.productSurvey.introductionQuestions[0].question}
                />
              </div>

              <div className="mt-8">
                <NextButton onClick={() => setStep(step + 1)} loading={loadingSubmission} />
              </div>
            </div>
          )}

          {step >= productQuestionStepStart && step <= productQuestionStepEnd && (
            <>
              <div>
                <ProductQuestionStep
                  currentProduct={currentProduct}
                  step={step}
                  setStep={setStep}
                  productQuestionStepEnd={productQuestionStepEnd}
                  updateAnswers={updateAnswers}
                  surveyQuestions={
                    surveyQuestions?.productSurvey?.[survey.subType]?.productQuestions
                  }
                />
              </div>
            </>
          )}

          {step > productQuestionStepEnd && (
            <div className="w-full flex justify-center text-center">
              <div className="mt-12 max-w-3xl">
                <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                  Thanks for completing the survey
                </h2>
                <p className="mt-2 text-base">
                  Findings from the survey will help your organisation make better decisions around
                  it's technology, so thanks for taking part.
                </p>

                <div className="flex justify-center">
                  <div className="max-w-sm">
                    <Lottie
                      loop={false}
                      animationData={successLottieJson}
                      play
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductSurveyPage
