import React from 'react'
import {
  calculateDaysBetweenDates,
  conditionalTableRowFormatting,
} from '../../../../utilities/general'

const SurveySummaryOutput = ({ survey, product, surveyQuestions }) => {
  const productCommentSummaries = survey.commentSummaries.find(
    commentSummary => commentSummary.productId === product.id
  )

  let parsedSummaries
  try {
    parsedSummaries = JSON.parse(productCommentSummaries?.summaryText)
  } catch (e) {}

  const roles = new Set()
  survey?.completions?.forEach(completion => completion.role && roles.add(completion.role))

  const heatmapSurveyQuestions = surveyQuestions?.filter(question => question.type === 'rating')

  const tableCellStyles = {
    border: '1px solid #cbd5e1',
    padding: '5px',
  }

  return (
    <>
      <p>
        The Bordercross Health pre-implementation survey served as an individualised hazard workshop
        for staff, capturing risk-related information specific to the implementation. The survey
        (Appendix A) was launched at {survey.organisationName} on{' '}
        {new Date(survey?.createdAt).toLocaleDateString()}.
      </p>

      <p>
        The objective of the workshop was to systematically identify, assess, and address risks
        associated with the implementation of {product.name}, with a focus on consequence scanning,
        psychological safety, and the holistic impact on organisational operations through
        multidisciplinary collaboration.
      </p>

      <h3>Survey Design and Participation</h3>
      <ul>
        <li>
          An asynchronous survey was distributed to participants to collect preliminary insights.
          The survey remained open for{' '}
          {calculateDaysBetweenDates(survey?.createdAt, survey?.closedDate)} days and was completed
          on {new Date(survey?.closedDate).toLocaleDateString()}.
        </li>
        <li>
          A total of {survey?.completions.length} participants from {survey.organisationName} took
          part representing a multidisciplinary workforce.
        </li>
        <li>
          The survey was disseminated by the Organisation's lead contact, who served as the
          nominated lead for this assessment.
        </li>
        <li>
          Participants included individuals in the following roles:
          <ul>
            {[...roles]?.map(role => (
              <li>{role}</li>
            ))}
          </ul>
        </li>
      </ul>

      <h3>Workshop Framework</h3>
      <p>
        The workshop was structured to foster <strong>psychological safety</strong> and encourage
        open, honest communication, ensuring that all participants felt empowered to share concerns,
        insights, and risks. This collaborative approach maximised the benefits of multidisciplinary
        input, allowing for diverse perspectives in the assessment of risks related to{' '}
        {product.name}.
      </p>
      <p>The framework included:</p>
      <ol>
        <li>
          <strong>Consequence Scanning</strong>: This approach explored the potential outcomes—both
          positive and negative—of implementing {product.name}, focusing on how these consequences
          might affect operations, patient outcomes, and regulatory compliance.
        </li>
        <li>
          <strong>Holistic Organisational Impact Assessment</strong>: The analysis incorporated the
          perspectives of participants from various disciplines to evaluate the wider organisational
          impact of {product.name}, ensuring a thorough understanding of the system-wide
          implications.
        </li>
      </ol>

      <h3>Methodology</h3>
      <ol>
        <li>
          <strong>Survey Phase</strong>: The survey outlined in Appendix A was used to gather
          participant insights and preliminary risk identification data. The survey contained{' '}
          {surveyQuestions?.length} questions tailored to evaluate specific areas of potential
          hazard, including:
          <ul>
            <li>Operational risks</li>
            <li>Clinical safety considerations</li>
            <li>Workflow integration challenges</li>
            <li>Identify hazards based on group expertise and experiences</li>
            <li>Psychological safety and its impact on team performance during implementation</li>
          </ul>
        </li>
        <li>
          <strong>Documentation and Reporting</strong>: Outcomes from the hazard workshop were
          compiled into a report, detailing:
          <ul>
            <li>The risks in the context of hazard analysis techniques</li>
            <li>Recommend control measures to mitigate identified hazards</li>
            <li>Identified risks and their potential consequences</li>
            <li>Recommended controls to reduce risk likelihood and/or severity</li>
            <li>
              Areas requiring further investigation or action prior to or during product
              implementation
            </li>
          </ul>
        </li>
      </ol>
      <br />

      <h3>Survey summary</h3>
      {parsedSummaries?.summary?.length > 0 && (
        <div>
          <h4 className="font-semibold">Overview</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.summary?.map(summaryPoint => (
              <li>{summaryPoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.benefits?.length > 0 && (
        <div>
          <h4 className="font-semibold">Potential benefits</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.benefits?.map(effectivePoint => (
              <li>{effectivePoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.risks?.length > 0 && (
        <div>
          <h4 className="font-semibold">Potential Risks</h4>
          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.risks?.map(challengePoint => (
              <li>{challengePoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}
      {parsedSummaries?.mitigations?.length > 0 && (
        <div>
          <h4 className="font-semibold">Suggested mitigations</h4>

          <ul className="text-sm list-disc pl-8">
            {parsedSummaries?.mitigations?.map(suggestionPoint => (
              <li>{suggestionPoint}</li>
            ))}
          </ul>
          <br />
        </div>
      )}

      <h3>Survey Responses Matrix</h3>
      <p>Key: 2 = Favourable, 0 = Neutral, -2 = Unfavourable</p>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ ...tableCellStyles, width: '300px' }}></th>
            {heatmapSurveyQuestions?.map(productQuestion => (
              <th style={{ ...tableCellStyles, width: '180px' }}>
                {productQuestion.shortQuestion}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {[...roles]?.map((role, index) => {
            const completionsForQuestionAndRole = new Set()
            surveyQuestions?.map(productQuestion => {
              survey?.completions?.forEach(completion => {
                completion?.answers?.forEach(answer => {
                  if (
                    answer.productId === product.id &&
                    answer.questionId === productQuestion.id &&
                    completion.role === role
                  ) {
                    completionsForQuestionAndRole.add(completion.id)
                  }
                })
              })
            })

            return (
              <tr key={index} style={{ background: conditionalTableRowFormatting(index) }}>
                <td style={tableCellStyles}>
                  <strong>{role}</strong> ({completionsForQuestionAndRole.size})
                </td>

                {heatmapSurveyQuestions?.map(productQuestion => {
                  const ratingsForQuestionAndRole = []
                  const answersForQuestionAndRole = []
                  survey?.completions?.forEach(completion => {
                    completion?.answers?.forEach(answer => {
                      if (
                        answer.productId === product.id &&
                        answer.questionId === productQuestion.id &&
                        completion.role === role
                      ) {
                        answersForQuestionAndRole.push(answer)
                        completionsForQuestionAndRole.add(completion.id)
                      }
                    })
                  })

                  answersForQuestionAndRole.forEach(answerForQuestionAndRole => {
                    if (typeof answerForQuestionAndRole.rating === 'number') {
                      ratingsForQuestionAndRole.push(answerForQuestionAndRole.rating)
                    }
                  })

                  if (ratingsForQuestionAndRole.length) {
                    let ratingsSum = 0
                    ratingsForQuestionAndRole.forEach(ratingForQuestionAndRole => {
                      ratingsSum += ratingForQuestionAndRole
                    })
                    const averageRating = ratingsSum / ratingsForQuestionAndRole.length

                    return (
                      <td style={tableCellStyles}>
                        <div>{parseFloat(Number(averageRating - 3).toFixed(2))}</div>
                      </td>
                    )
                  } else {
                    return (
                      <td style={tableCellStyles}>
                        <div>N/A</div>
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <p>Individual survey responses are available in Appendix B.</p>
    </>
  )
}

export default SurveySummaryOutput
